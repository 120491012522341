import * as React from "react";

const LogoIcon = ({ size, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 50 50"
    fill="none"
    {...props}
  >
    <path fill={color} d="M0 25h50c0 13.807-11.193 25-25 25S0 38.807 0 25Z" />
    <path
      fill={color}
      d="M0 25 43.301 0c6.904 11.957 2.807 27.247-9.15 34.15C22.193 41.055 6.904 36.958 0 25Z"
    />
  </svg>
);

export default LogoIcon;
