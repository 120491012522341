import React from "react";
import "./App.css";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import content from "./content/medical-clinic.json";
import image from "./assets/talos-image.jpg";

function App() {
  const { navigationBar, hero, features, testimonials, footer } = content;

  return (
    <div>
      <NavigationBar
        title={navigationBar.title}
        button={navigationBar.button}
        url={navigationBar.url}
      />
      <section className="bg-zinc-100 px-16 py-16 flex items-center">
        <div className="max-w-7xl container mx-auto flex flex-col md:flex-row items-center gap-16">
          <div className="md:w-1/2">
            <h1 className="text-5xl text-gray-800 mb-4 font-outfitSemiBold">
              {hero.title}
            </h1>
            <p className="text-gray-600 mb-6">{hero.subtitle}</p>
            <a
              href={hero.url}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-slate-900 text-white font-bold py-3 px-4 rounded-md hover:bg-slate-600"
            >
              {hero.button}
            </a>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img src={image} alt="Hero" className="w-full h-auto rounded-lg" />
          </div>
        </div>
      </section>

      <section className="bg-white py-16 px-16">
        <div className="container mx-auto max-w-7xl">
          <h2 className="text-3xl font-outfitSemiBold text-center text-gray-800 mb-12">
            Why Talos?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="p-6 rounded-md border">
                <i
                  className={`fi w-10 h-10 text-yellow-500 text-4xl flex items-center justify-center fi-br-caret-down ${feature.icon}`}
                />
                <h3 className="text-2xl font-bold my-2 mt-3">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-zinc-100 py-16 px-16">
        <div className="container mx-auto max-w-7xl">
          <h2 className="text-3xl text-center text-gray-800 mb-12 font-outfitSemiBold">
            What Our Customers Say
          </h2>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="max-w-xl mx-auto bg-white p-6 rounded-md shadow-lg"
            >
              <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
              <a
                href={testimonial.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-900 hover:underline mt-4 block w-fit"
              >
                - {testimonial.author}
              </a>
            </div>
          ))}
        </div>
      </section>

      <Footer content={footer} />
    </div>
  );
}

export default App;
