import React from "react";
import LogoIcon from "../Icons/LogoIcon";

const NavigationBar = ({ title, button, url }) => {
  return (
    <nav className="bg-white bg-opacity-95 border px-16 sticky top-0 z-50">
      <div className="container mx-auto py-3 max-w-7xl flex justify-between items-center">
        <div className="flex items-center">
          <a href="/" className="flex items-center">
            <LogoIcon size={25} color={"#eab308"} />
            <p className="ml-2 text-2xl text-yellow-500 font-outfitSemiBold">
              {title}
            </p>
          </a>
        </div>
        <div className="hidden sm:flex items-center space-x-4">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-slate-900 text-white font-bold py-2 px-4 rounded hover:bg-slate-600"
          >
            {button}
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
